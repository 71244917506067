import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Header = props => (
  <header id="header" className="alt">
    {/* <span className="logo">
      <img style={{ maxWidth: '6vw', minWidth: 100 }} src={logo} alt="" />
    </span> */}
    <h1>Bryan Gottschalk</h1>
    <p style={{ marginBottom: '1em' }}>Software Engineer</p>
    <ul className="icons">
      <li>
        <OutboundLink
          href="https://github.com/bryangottschalk"
          className="icon fa-github alt"
        >
          <span className="label">GitHub</span>
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          href="https://www.linkedin.com/in/bryangottschalk/"
          className="icon fa-linkedin alt"
        >
          <span className="label">LinkedIn</span>
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          href="mailto:bryanlgottschalk@gmail.com"
          className="icon fa-envelope alt"
        >
          <span className="label">GitHub</span>
        </OutboundLink>
      </li>
    </ul>
  </header>
)

export default Header
