import { graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';
import Helmet from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Header from '../components/Header';
import Layout from '../components/layout';
import Nav from '../components/Nav';
import { Slideshow } from '../components/Slideshow';
import headshot from '../assets/images/headshot.png';
import makeid from '../helper-functions/makeid';
const isBrowser = typeof window !== 'undefined'; // can't use window when building app server-side

const ProjectTitle = styled.h2`
  margin: 0px;
  padding: 0px;
`;

const SubTitle = styled.h3`
  margin-bottom: 4px;
  font-style: italic;
  @media screen and (max-width: 980px) {
    /* font-weight: bold; */
    /* font-size: 16px; */
  }
`;

const ProjectParagraph = styled.div`
  margin: 0px 0px 10px 5px;
  @media screen and (max-width: 980px) {
    /* font-size: 14px; */
  }
`;

const ProjectHeader = styled.div`
  margin-left: 5px;
`;

const ProjectWrapper = styled.div`
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
  text-align: left;
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
  margin-bottom: 24px;
  // margin: 50px 0px;
`;

const TechnologiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Technologies = styled.div`
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid #636363;
  border-radius: 5px;
`;

const ResponsiveContainerOne = styled.div`
  @media screen and (min-width: 980px) {
    width: 50%;
  }
  @media screen and (max-width: 979px) {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }
`;

const ResponsiveContainerTwo = styled.div`
  @media screen and (min-width: 980px) {
    width: 55%;
    padding: 16px;
  }
  @media screen and (max-width: 979px) {
    width: 100%;
    display: block;
  }
`;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    };
  }
  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  };

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  };

  render() {
    const projects = this.props.data.allMarkdownRemark.edges;

    return (
      <Layout>
        <Helmet title="Portfolio | Bryan Gottschalk" />
        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />
        <div id="main">
          <section id="intro" className="main special">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>About</h2>
                </header>
                <p style={{ textAlign: 'left' }}>
                  I'm a highly motivated software engineer experienced with a
                  variety of web development technologies. I'm passionate about
                  building intuitive, responsive, and accessible user interfaces
                  and experiences, as well as creating and ingesting REST APIs.
                  I currently specialize in JavaScript and TypeScript
                  development using React, Node.js and Amazon Web Services (AWS)
                  for cloud computing. I'm always eager to expand my skill-set
                  and collaborate with others to build something great together.
                </p>
                <p style={{ textAlign: 'left' }}>
                  I'm currently working at civil engineering firm HNTB building
                  web applications for several State Departments of
                  Transportation (DOTs) and other government entities. This
                  portfolio site is built with Gatsby.js by dynamically
                  rendering markdown file data with GraphQL and React.
                </p>
              </div>
              <span className="image">
                <img src={headshot} alt="" />
              </span>
            </div>
            <div className="spotlight">
              <OutboundLink
                target="_blank"
                style={{ fontSize: '1.5rem' }}
                href={'/Bryan_Gottschalk_Resume_2022.pdf'}
              >
                Technical Resume
              </OutboundLink>
            </div>
          </section>
          <section id="projects" className="main special">
            <header className="major">
              <h2>Projects</h2>
            </header>
            {/* Dynamically render project using Gatsby GraphQL */}

            {projects.map((project, idx) => {
              const {
                title,
                subtitle,
                date,
                deployedUrl,
                githubUrl,
                videoUrl,
                description,
                techUsed,
                images,
                displayOrder
              } = project.node.frontmatter;

              return (
                <>
                  {displayOrder > 0 && (
                    <ProjectWrapper key={makeid(22)}>
                      <ResponsiveContainerOne>
                        <Slideshow images={images}></Slideshow>
                      </ResponsiveContainerOne>
                      <ResponsiveContainerTwo>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <ProjectHeader>
                              <ProjectTitle>{title}</ProjectTitle>
                              {subtitle && (
                                <SubTitle>{`${subtitle} (${date})`}</SubTitle>
                              )}
                            </ProjectHeader>
                            <div style={{ marginTop: 10 }}>
                              {videoUrl && (
                                <OutboundLink
                                  style={{
                                    marginLeft: 0,
                                    marginRight: 10,
                                    marginBottom: 10
                                  }}
                                  href={videoUrl}
                                  className="icon fa-youtube alt"
                                >
                                  <span className="label">Video</span>
                                </OutboundLink>
                              )}
                              {deployedUrl && (
                                <OutboundLink
                                  style={{ marginRight: 10, marginBottom: 10 }}
                                  href={deployedUrl}
                                  className="icon fa-external-link alt"
                                >
                                  <span className="label">Deployed</span>
                                </OutboundLink>
                              )}
                              {githubUrl && (
                                <OutboundLink
                                  href={githubUrl}
                                  className="icon fa-github alt"
                                >
                                  <span className="label">GitHub</span>
                                </OutboundLink>
                              )}
                            </div>
                          </div>
                        </div>

                        <ProjectParagraph>
                          {description}
                          {title === 'Crystal Baller' && (
                            <div>
                              <div style={{ padding: 5 }}></div>
                              Play in practice mode alone or open the project on
                              two devices or windows for multiplayer.
                            </div>
                          )}
                          {title === 'Trail Tracker' && (
                            <div>
                              <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <i>
                                  Project is deployed but currently under
                                  construction.
                                </i>
                                <div>
                                  Plans for the future are to add a chart
                                  library to give more information about
                                  destinations, as well as user logins to
                                  persist data and allow for tracking completed
                                  hikes.
                                </div>
                              </div>
                            </div>
                          )}
                        </ProjectParagraph>

                        <div>
                          <TechnologiesWrapper>
                            {techUsed.map((technology, idx) => {
                              return (
                                <Technologies key={makeid(23)}>
                                  {technology}
                                </Technologies>
                              );
                            })}
                          </TechnologiesWrapper>
                        </div>
                      </ResponsiveContainerTwo>
                    </ProjectWrapper>
                  )}
                </>
              );
            })}
          </section>
        </div>
      </Layout>
    );
  }
}

export default Index;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___displayOrder }) {
      edges {
        node {
          frontmatter {
            displayOrder
            title
            subtitle
            type
            date
            deployedUrl
            githubUrl
            videoUrl
            description
            techUsed
            images {
              id
              publicURL
            }
          }
        }
      }
    }
  }
`;
