import React from 'react';
import styled from 'styled-components';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import Swiper core and required modules
import SwiperCore, { Zoom, Navigation, Pagination } from 'swiper';
import makeid from '../helper-functions/makeid';
// install Swiper modules
SwiperCore.use([Pagination]);

const EachSlide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  width: 100%;
  padding: 16px;
`;

const PaginationContainer = styled.div`
  height: 40px;
  width: 100%;
`;
export const Slideshow = (props) => {
  return (
    <div style={{ padding: 24 }}>
      <Swiper
        key={makeid(19)}
        modules={[Navigation, Pagination, Zoom]}
        pagination={true}
        navigation={true}
      >
        {props.images &&
          props.images.map((image, idx) => {
            return (
              <div key={makeid(20)}>
                <SwiperSlide key={makeid(21)}>
                  <EachSlide
                    style={{
                      backgroundImage: `url(${image.publicURL})`
                    }}
                  ></EachSlide>
                </SwiperSlide>
              </div>
            );
          })}
        <PaginationContainer />
      </Swiper>
    </div>
  );
};
