import React, { ReactElement, useEffect } from 'react'
import '../assets/scss/main.scss'
import { RootState } from '../store'
import { useSelector } from 'react-redux'
import Footer from './Footer'
import { navigate } from 'gatsby'

interface Props {
  children: any
}

export default function Layout({ children }: Props): ReactElement {
  const { isLoggedIn } = useSelector((state: RootState) => state).userSlice
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    }
  }, [isLoggedIn])
  return (
    <div>
      {isLoggedIn && (
        <div id="wrapper">
          {children}
          <Footer />
        </div>
      )}
    </div>
  )
}
